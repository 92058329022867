
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import MediaListComponent from "@/components/media/MediaListComponent.vue"
import PropertyContacts from "@/components/properties/PropertyContacts.vue"
import JobsTableClientPaged from "@/components/jobs/JobsTableClientPaged.vue"
import PropertyMaintenanceSystemsPanel from "./PropertyMaintenanceSystemsPanel.vue";
import { PropertyResource } from "@/resources";
import ClientName from "@/components/clients/ClientName.vue"
import PropertyAddress from '@/components/properties/PropertyAddress.vue'
import InstantUpdateBoolean from '../forms/InstantUpdateBoolean.vue'
import PropertyAddDialog from '@/components/properties/PropertyAddDialog.vue'
import { BasePropertyComponent } from "../base/BaseMixins";


@Component({components : {BasePropertyComponent, PropertyAddDialog, InstantUpdateBoolean, PropertyAddress, ClientName, PropertyMaintenanceSystemsPanel, MediaListComponent, PropertyContacts, JobsTableClientPaged}})
export default class PropertyOverview extends Vue {
  @InjectReactive() propertyResource!: PropertyResource

  addPropertyDialog = false
  selectedPropertyResource : PropertyResource | null = null


  async editProperty() {
    this.selectedPropertyResource = new PropertyResource(this.propertyResource.data().id)
    await this.selectedPropertyResource.get()
    this.addPropertyDialog = true
  }
  
  get customDialogContextLabel() {
    return "Property file : " + this.propertyResource.data().address.address;
  }
 
}
