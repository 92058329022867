
import { Component, Prop } from "vue-property-decorator"
import BasePanel from '@/components/base/BasePanel.vue'
import { BasePropertyComponent } from "../base/BaseMixins"
import ContactsList from "../contacts/ContactsList.vue"
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue"
import ContactFormDialog from "../contacts/ContactFormDialog.vue";
import { BaseClientComponent } from "../base/BaseMixins"
import { ContactResource } from "@/resources"
import BaseDeleteAssociationResourceItemDialog from "../base/BaseDeleteAssociationResourceItemDialog.vue"
import PropertyClientAddClientDialog from "./PropertyClientAddClientDialog.vue"
import PropertyClientDetachDialog from "./PropertyClientDetachDialog.vue"

@Component({components : {PropertyClientDetachDialog, BaseClientComponent, PropertyClientAddClientDialog, BaseDeleteAssociationResourceItemDialog, ContactFormDialog, BasePanel, BaseOptionsMenu, ContactsList}})
export default class PropertyContacts extends BasePropertyComponent { 
  @Prop({ default : "400px" }) readonly height!: string
  
  contactDialog : boolean = false
  deleteDialog : boolean = false
  addClientDialog : boolean = false
  detachClientDialog : boolean = false
  activePanel : number = 0
  selectedContact !: ContactResource | undefined
  cid : any = null
  detaching : boolean = false

  get contactUtil() {
    return ContactResource
  }

  async postEntityUpdate() {
    this.cid = null
    try {
      this.cid = (await this.resource.client.getAssociation({}, false)).data()?.id
    } 
    catch (e) { 
      // ignore
    }
  }

  gotoClient() {
    this.$router.push({name: 'client', params: {cid:this.cid}})
  }

  addClient() {
    this.addClientDialog = true
  }

  async detachClient() {
    this.detaching = true
    try {
      //
    } catch (e : any) {
      //
    } finally {
      this.detaching = false
    }
  }

  deletePropertyContact(contact : ContactResource) {
    this.selectedContact = contact
    this.deleteDialog = true
  }

  editPropertyContact(contact : ContactResource) {
    this.selectedContact = contact
    this.contactDialog = true
  }

  addPropertyContact() {
    this.selectedContact = undefined
    this.contactDialog = true
  }
    
}
