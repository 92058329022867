
import {Component} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import {PropertyResource, ClientResource} from "@/resources"
import { BasePropertyComponent } from '../base/BaseMixins'
import ClientName from '../clients/ClientName.vue'

@Component({components: {BaseDialog, ClientName}})
export default class PropertyClientDetachDialog extends BasePropertyComponent {

  detaching : boolean = false
  detachError : any | null = null

  get clientUtil() {
    return ClientResource
  }

  get propertyUtil() {
    return PropertyResource
  }

  detach() {
    this.detaching = true
    this.detachError = null

    // fetch the client assocition on the property side and delete
    this.resource.client.delete().then(() => {
        this.$emit('input', false)
        this.$emit('update', true)
      })
    .catch((err) => {
        console.error(err)
        this.detachError = err
    }).finally(() => {
      this.detaching = false
    })
  }

}
