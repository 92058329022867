
import { Component, Prop } from "vue-property-decorator"
import BasePanel from '@/components/base/BasePanel.vue'
import { BasePropertyMaintenanceSystemsComponent } from "../base/BaseMixins"
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue"
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"
import SystemAndPackageMenuSelector from '@/components/maintenanceSystems/SystemAndPackageMenuSelector.vue'
import { MaintenanceSystemsResource, PropertyMaintenanceSystemsResource } from "@/resources"
import HealthSummaryChips from "../healthreports/HealthSummaryChips.vue"

@Component({components : {HealthSummaryChips, SystemAndPackageMenuSelector, PropertyMaintenanceSystemFormDialog, BasePanel, BaseOptionsMenu}})
export default class PropertyMaintenanceSystemsPanel extends BasePropertyMaintenanceSystemsComponent { 
  @Prop({ default : "400px" }) readonly height!: string
  
  // TODO duped in the navigator
  preppingAdd : any = {}
  addMenu : boolean = false
  addDialog : boolean = false
  addMenuX : number = 0
  addMenuY : number = 0
  addMenuType !: "System" | "Subsystem" | "Component"

  selectedMaintenanceSystemResource !: MaintenanceSystemsResource
  parentPropertyMaintenanceSystemResource !: PropertyMaintenanceSystemsResource

  gotoSystem(pms : PropertyMaintenanceSystemsResource) {
    this.$router.push({name:'propertySystem', params:{pmsId:pms.data().id}})
  }

  showAddDialog(selectedMaintenanceSystem : any) {
    this.selectedMaintenanceSystemResource = selectedMaintenanceSystem
    this.addDialog = true
  }

  /**
   * Returns only the systems
   */
  get filteredSystems() {
    return this.resources.filter(r => r.data().parentSystemId == this.rootSystem.id)
  }

  /**
   * Returns the PMS data for the root maintenance system resource.
   */
   get rootSystem() {
    return this.rdata.filter((r:any) => !r.maintenanceSystemParentId).map((r:any) => ({...r}))[0]
  }

  // TODO duped in nav
  showAddMenu(parentSystem : any,  addMenuType : "System" | "Subsystem" | "Component", evt : MouseEvent) {
    this.addMenu = false
    this.$set(this.preppingAdd, parentSystem.id, true)

    this.parentPropertyMaintenanceSystemResource = new PropertyMaintenanceSystemsResource(parentSystem.id)
    var promises : Promise<any>[] = [
      this.parentPropertyMaintenanceSystemResource.get(), 
      this.parentPropertyMaintenanceSystemResource.maintenanceSystem.getAssociation()]

    Promise.allSettled(promises).then((results) => {
      if (results[0].status == 'fulfilled' && results[1].status == 'fulfilled') {
        this.selectedMaintenanceSystemResource = results[1].value

        // from vuetify example without activator
        this.addMenuX = evt.clientX
        this.addMenuY = evt.clientY
        this.addMenuType = addMenuType
        evt.preventDefault()
        this.$nextTick(() => {
          this.$set(this.preppingAdd, parentSystem.id, false)
          this.addMenu = true
        })
      }
    })
  }
    
}
